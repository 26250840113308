// Step 1: Import React
import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout2 from '../../components/layout2'
import Seo2 from '../../components/seo2'
import Disclaimer from '../../components/disclaimer'
import { blogTitle, blogBullets } from '../../components/layout.module.css'

const GuidePage = ({ data }) => {
  return (
    <Layout2 pageTitle="Buying Guides">
      {
        data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <h2>
              <Link className={blogTitle} to={`/guides/${node.frontmatter.slug}`}>
                {node.frontmatter.title}
              </Link>
              </h2>
            <ul>
              <li className={blogBullets}>Posted: {node.frontmatter.date}</li>
              <li className={blogBullets}>Location: {node.frontmatter.location}</li>
            </ul>
            <p>{node.excerpt}</p>

          </article>
        ))
      }
              <Disclaimer />
    </Layout2>
  )
}

// searches only for blogs in /blog/reviews directory
export const query = graphql`
    query {
      allMdx(
        sort: {fields: frontmatter___date, order: DESC}
        filter: {internal: {contentFilePath: {regex: "//guides//"}}}
      ) {
        nodes {
          frontmatter {
            title
            slug
            date(formatString: "MMMM D, YYYY")
            location
          }
          id
          excerpt
          parent {
            ... on File {
              modifiedTime(formatString: "MMMM D, YYYY")
            }
            internal {
              contentFilePath
            }
          }
        }
      }
    }
    `

export const Head = () => <Seo2 title="Guides" />

export default GuidePage